* {
  box-sizing: border-box;

  &:active,
  &:hover,
  &:focus {
    outline: 0;
  }
}

html {
  height: 100%;
  height: 100dvh;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  width: 100%;
  height: 100%;
  background-color: $body-color-bg;
  color: $body-color-text;
  font: 14px BostonRegular, sans-serif;
  font-weight: 400;
  padding: 0;
  margin: 0;

  overscroll-behavior-y: contain;
}

@import "typo";
