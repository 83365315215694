.lobby {
  max-width: 768px;
  margin: auto;
  padding: 12px 30px;

  h5 {
    color: #111111;
    margin: 0 8px 12px 8px;
  }
}

.banner {
  position: relative;
  margin: 0 20px;

  .title {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;

    margin: auto;

    h2 {
      color: #111111;
      text-align: center;
    }

    &>span {
      width: 18px;
      height: 18px;
    }

    .exit {
      color: #0BAEEB;
      transform: rotate(180deg);
      text-transform: uppercase;
    }
  }

  .description {
    padding: 0 16px;
    font-size: 16px;
    text-align: center;
  }
}

.option {
  width: 100%;
  margin-bottom: 16px;
}

inka-cell {
  width: 100%;
  height: 100%;
}

inka-info {
  margin-left: 12px;
}

.radio-group {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;

  .radio,
  .checkbox {
    width: 100%;
    max-height: 320px;
    text-align: center;
    font-size: 14px;
    line-height: 18px;

    &.card {
      label {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 18vh;
        max-height: 33vw;
        color: #C4C4C4;
      }

      .star {
        background: linear-gradient(180deg, #BBA5FD 0%, #A588FC 100%);
      }

      .moon {
        background: linear-gradient(179.51deg, #0BAEEB 0.42%, #038ABC 221.96%);
      }

      .diamond {
        background: linear-gradient(180deg, #3A3886 0%, #252363 100%);
      }
    }

    label {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding: 12px 8px;
      border: 1px solid #E1E8E8;


      //background: linear-gradient(180deg, #BBA5FD 0%, #A588FC 100%);
      box-shadow: 0 4px 16px rgba(86, 112, 134, 0.15);
      border-radius: 16px;
      transition: all 0.1s ease-out;

      &.static {
        background: #FFFFFF;
        position: relative;

        .example {
          position: absolute;
          top: 20px;
          left: 20px;
          bottom: 50px;
          right: 20px;
          //border-radius: 16px;

          &.fifteen {
            background: url("/assets/images/fifteen.svg") center no-repeat;
            background-size: 100%;
          }

          &.memoryCards {
            background: url("/assets/images/memoryCards.svg") center no-repeat;
            background-size: 100%;
          }

          &.findNumber {
            background: url("/assets/images/findNumber.svg") center no-repeat;
            background-size: 100%;
          }

          &.mascot {
            background: url("/assets/images/mascot/happy.png") center no-repeat;
            background-size: 75%;
          }

          &.meditation {
            background: url("/assets/images/meditation.svg") center no-repeat;
            background-size: 75%;
          }

          &.quiz {
            background: url("/assets/images/quiz.svg") center no-repeat;
            background-size: 75%;
          }
        }
      }

      .header {
        margin: 0;
        font-size: 18px;
        color: #111111;
      }

      &.card-example {
        padding: 0;
      }
    }

    input {
      display: none;

      &:checked~label {
        border-color: #46C783;
        color: #111;
        background: #FFFFFF;
        overflow: hidden;
        box-shadow: 0 4px 16px rgba(86, 112, 134, 0.15);
      }
    }
  }
}

.muscle-tension,
.meditation-label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #FFFFFF;
  color: #111111;
  padding: 18px 24px;
  margin-bottom: 16px;

  border-radius: 16px;
  border: 1px solid #E1E8E8;
  box-shadow: 0 4px 16px rgba(86, 112, 134, 0.15);

  position: relative;

  .content {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-direction: column;
  }

  .ten {
    width: 48px;
    height: 47px;
    background: url("/assets/images/ten.svg") no-repeat;
    background-position: center;
    background-size: 100%;
    margin-right: 25px;
    margin-left: 12px;
  }

  h3 {
    margin: 0;
    font-size: 18px;
    color: #111111;
  }

  .description {
    font-size: 14px;
    text-align: center;
    color: #666;

    &.success {
      span {
        font-family: BostonBold, sans-serif;
        color: #46C783;
      }
    }
  }
}

.start {
  padding: 0 20px;
  width: 100%;
  display: flex;
  justify-content: center;

  button {
    width: 100%;
    max-width: 768px;
    color: #FFFFFF;
    background: #46C783;
    box-sizing: border-box;
    border: none;
    border-radius: 16px;
    font-size: 22px;
    font-family: BostonBold, sans-serif;
    padding: 10px;
    outline: none;
    margin: 16px 0;
  }
}

.absolute {
  position: absolute;
}
