@import "fonts";

p {
  margin: 0;
}

h1, h2, h3, h4, h5, h6, b, strong {
  font-family: BostonBold, sans-serif;
}

.text-center {
  text-align: center;
}

h4, h5 {
  margin: 7px 0 24px 0;
}

h5 {
  font-size: 18px;
}
