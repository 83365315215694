.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 777;

  &-content {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    background: #F9F9F9;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(111.37deg, #ECD1FA 4.95%, #C3B8FF 97.95%);

      opacity: 0;

      transition: opacity 1s ease-out;
      z-index: 1;
    }

    &.success {
      &:before {
        opacity: 1;
      }
    }

    padding: 32px 24px;
  }

  &-message {
    font-size: 16px;
    position: relative;
    padding: 30px 24px;
    background: #FFFFFF;
    border-radius: 30px;
    box-shadow: 0 4px 16px rgba(86, 112, 134, 0.15);
    min-width: 160px;

    text-align: center;

    z-index: 2;

    line-height: 24px;

    svg {
      position: absolute;
      top: calc(100% - 2px);
      filter: drop-shadow(0 6px 4px rgba(86 112 13 / 0.15));
    }

    .title {
      font-family: BostonRegular, sans-serif;
      white-space: pre-wrap;
    }

    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 140%;
      right: calc(50% - 84px);
      width: 142px;
      height: 136px;
      background: url("/assets/images/mascot/hero.png") no-repeat;
      background-size: 142px;
      background-position-y: 30%;
      //filter: drop-shadow(0 24px 24px rgba(0, 0, 0, 0.24));
      border-radius: 50%;
      box-shadow: 0 24px 24px rgba(0, 0, 0, 0.24);

      overflow: visible;
    }

    &.compress {
      &:after {
        background: url("/assets/images/mascot/concerned.png") no-repeat;
        background-size: 140px;
      }
    }

    &.happy {
      &:after {
        background-position-y: 20%;
      }
    }

    &.calm {
      &:after {
        background-position-y: 50%;
      }
    }

    &.sad {
      &:after {
        background-position-y: 90%;
      }
    }
  }

  .filler {
    @media screen and (max-height: 714px) {
      display: none;
    }

    &.actions {
      display: block;
      height: 148px;
    }
  }
}

.actions {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 3;

  button {
    width: 100%;
    max-width: 420px;
    height: 50px;
    margin-top: 24px;
    font-size: 18px;
    border-radius: 16px;
    border: none;
    box-shadow: 0 4px 16px rgba(86, 112, 134, 0.15);
    user-select: none;
    &.accept {
      background: #079BD1;
      color: #FFFFFF;
    }

    &.decline {
      background: #FFFFFF;
      color: #079BD1;

      &.accept {
        background: #079BD1;
        color: #FFFFFF;
      }
    }
  }
}

button {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.control {
  label {
    font-size: 16px;
    display: inline-flex;
    align-items: center;
    font-family: BostonBold, sans-serif;

    .text {
      margin: 0 16px;
    }

    &:before {
      width: 100%;
      height: 100%;
      border: 2px solid #E1E8E8;
      border-radius: 4px;
    }
  }

  input[type="checkbox"] {
    width: 24px;
    height: 24px;
    visibility: hidden;
    display: none;

    &:checked {
      & ~ label {
        color: #111111;
      }
    }
  }
}
