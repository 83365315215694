.tag {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(17, 17, 17, 0.50);
  font-size: 24px;
  background: #FFFFFF;
  box-shadow: 0 2px 8px rgba(86, 112, 134, 0.2);
  border: 1px solid rgba(197, 198, 205, 0.5);
  border-radius: 12px;
  transition: background-color 0.2s ease-out, color 0.1s ease-out;

  &.at-home {
    background: #46C783;
    color: #FFFFFF;
    box-shadow: inset 0 2px 4px rgba(86, 112, 134, 0.5);
  }

  &.paradox {
    background: #fcbf49;
    color: #FFFFFF;
    box-shadow: inset 0 2px 4px rgba(86, 112, 134, 0.5);
  }
}

.done {
  .tag {
    color: #46C783;
    border: 1px solid #46C783;
    box-shadow: none !important;
    background: #f9f9f9 !important;
  }
}
