@mixin src($path, $family, $format) {
  src: url("../../assets/fonts/#{$path}/#{$family}.#{$format}") format("truetype");
}

@mixin font($family, $path, $weight: 400, $format: "ttf") {
  @font-face {
    font-family: $family;
    @include src($path, $family, $format);
    font-weight: $weight;
    font-style: normal;
    font-stretch: normal;
  }
}

// Boston
@include font("BostonRegular", Boston, 400, "otf");
@include font("BostonBold", Boston, 700, "otf");

// Find number game fonts
@include font("Monoton-Regular", Monoton);
@include font("Miltonian-Regular", Miltonian);
@include font("Limelight-Regular", Limelight);
@include font("BungeeShade-Regular", Bungee_Shade);
@include font("CraftyGirls-Regular", Crafty_Girls);
