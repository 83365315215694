@keyframes gradient {
  0% {
    opacity: 0
  }
  5% {
    opacity: 1
  }
  95% {
    opacity: 1
  }
  1000% {
    opacity: 0
  }
}

.sky-gradient {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: gradient 240s infinite linear;
  opacity: 0;
}

/* Gradients */
.sky-gradient-00, .sky-gradient-24 {
  background: #00000c;
}

.sky-gradient-24 {
  animation-delay: 240s;
}

.sky-gradient-01 {
  background: linear-gradient(to bottom, #020111 85%, #191621 100%);
  animation-delay: 10s;
}

.sky-gradient-02 {
  background: linear-gradient(to bottom, #020111 60%, #20202c 100%);
  animation-delay: 20s;
}

.sky-gradient-03 {
  background: linear-gradient(to bottom, #020111 10%, #3a3a52 100%);
  animation-delay: 30s;
}

.sky-gradient-04 {
  background: linear-gradient(to bottom, #20202c 0%, #515175 100%);
  animation-delay: 40s;
}

.sky-gradient-05 {
  background: linear-gradient(to bottom, #40405c 0%, #6f71aa 80%, #8a76ab 100%);
  animation-delay: 50s;
}

.sky-gradient-06 {
  background: linear-gradient(to bottom, #4a4969 0%, #7072ab 50%, #cd82a0 100%);
  animation-delay: 60s;
}

.sky-gradient-07 {
  background: linear-gradient(to bottom, #757abf 0%, #8583be 60%, #eab0d1 100%);
  animation-delay: 70s;
}

.sky-gradient-08 {
  background: linear-gradient(to bottom, #82addb 0%, #ebb2b1 100%);
  animation-delay: 80s;
}

.sky-gradient-09 {
  background: linear-gradient(to bottom, #94c5f8 1%, #a6e6ff 70%, #b1b5ea 100%);
  animation-delay: 90s;
}

.sky-gradient-10 {
  background: linear-gradient(to bottom, #b7eaff 0%, #94dfff 100%);
  animation-delay: 100s;
}

.sky-gradient-11 {
  background: linear-gradient(to bottom, #9be2fe 0%, #67d1fb 100%);
  animation-delay: 110s;
}

.sky-gradient-12 {
  background: linear-gradient(to bottom, #90dffe 0%, #38a3d1 100%);
  animation-delay: 120s;
}

.sky-gradient-13 {
  background: linear-gradient(to bottom, #57c1eb 0%, #246fa8 100%);
  animation-delay: 130s;
}

.sky-gradient-14 {
  background: linear-gradient(to bottom, #2d91c2 0%, #1e528e 100%);
  animation-delay: 140s;
}

.sky-gradient-15 {
  background: linear-gradient(to bottom, #2473ab 0%, #1e528e 70%, #5b7983 100%);
  animation-delay: 150s;
}

.sky-gradient-16 {
  background: linear-gradient(to bottom, #1e528e 0%, #265889 50%, #9da671 100%);
  animation-delay: 160s;
}

.sky-gradient-17 {
  background: linear-gradient(to bottom, #1e528e 0%, #728a7c 50%, #e9ce5d 100%);
  animation-delay: 170s;
}

.sky-gradient-18 {
  background: linear-gradient(to bottom, #154277 0%, #576e71 30%, #e1c45e 70%, #b26339 100%);
  animation-delay: 180s;
}

.sky-gradient-19 {
  background: linear-gradient(to bottom, #163C52 0%, #4F4F47 30%, #C5752D 60%, #B7490F 80%, #2F1107 100%);
  animation-delay: 190s;
}

.sky-gradient-20 {
  background: linear-gradient(to bottom, #071B26 0%, #071B26 30%, #8A3B12 80%, #240E03 100%);
  animation-delay: 200s;
}

.sky-gradient-21 {
  background: linear-gradient(to bottom, #010A10 30%, #59230B 80%, #2F1107 100%);
  animation-delay: 210s;
}

.sky-gradient-22 {
  background: linear-gradient(to bottom, #090401 50%, #4B1D06 100%);
  animation-delay: 220s;
}

.sky-gradient-23 {
  background: linear-gradient(to bottom, #00000c 80%, #150800 100%);
  animation-delay: 230s;
}
